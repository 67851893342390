import React from "react";
import styles from "./AboutMeCard.module.css";

const AboutMe = () => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.story}>
        I am a passionate front-end web developer who likes to create fast,
        responsive and good user interfaces. Major technologies I work with -
        React and Angular frameworks. I am also able to create a back-end for an
        application with node+express and PSQL. I constantly work on improving
        my existing skills, acquiring new ones and keeping up-to-date with
        modern technologies and trends in web-development.
      </p>
      <p className={styles.story}>
        I look for an opportunity to become a part of a team where I can
        leverage my current skills and continue to develop as a web developer.
      </p>
    </div>
  );
};

export default AboutMe;
